import React, { Component } from 'react'
import Lightbox from 'react-image-lightbox'
import Layout from '../../components/layout/layout'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from "../../components/seo"
import CCBreadcrumb from '../../components/layout/breadcrumb'
import SideBarSeTwo from "../../components/layout/sidemenu/sidemenu_se_two"

import Alert from 'react-bootstrap/Alert'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import ContentHeader from '../../components/page-sections/content-header'
import FocusQuestion from '../../components/page-sections/focus-question'
import Introduction from '../../components/page-sections/introduction'
import ButtonTop from '../../components/buttons/button-top'
import ButtonNext from '../../components/buttons/button-next'
import rowImage from '../../queries/images/row-image'

import 'react-image-lightbox/style.css'

import unit2Icon from '../../images/icons/unit_02_icon.svg'
import co2TwoDays45 from '../../images/student-pages/unit-2/45_co2_two_days.gif'
import co2Natural46 from '../../images/student-pages/unit-2/46_co2_natural_mauna.gif'
import denverPlant47 from '../../images/student-pages/unit-2/47_denver_powerplant.gif'
import co2PointBarrow49 from '../../images/student-pages/unit-2/49_co2_point_barrow.gif'
import northernHemA50 from '../../images/student-pages/unit-2/50_northern_hemisphere_co2_2002.gif'
import northernHemB51 from '../../images/student-pages/unit-2/51_northern_hemisphere_co2_1980.gif'
import co2SouthPole52 from '../../images/student-pages/unit-2/52_co2_south_pole.gif'
import co2SouthPoleMauna53 from '../../images/student-pages/unit-2/53_co2_south_pole_mauna.gif'


const EarthTakesABreath25 = class extends
Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndexA: 0,
      photoIndexB: 0,
      AisOpen: false,
      BisOpen: false
    };
  }

  render() {
    const { photoIndexA, AisOpen } = this.state;
    const imageSetA = [
      this.props.data.imagePB1.childImageSharp.fluid.src,
      this.props.data.imagePB2.childImageSharp.fluid.src,
      this.props.data.imagePB3.childImageSharp.fluid.src,
      this.props.data.imagePB4.childImageSharp.fluid.src,
      this.props.data.imagePB5.childImageSharp.fluid.src
    ]

    const { photoIndexB, BisOpen } = this.state;
    const imageSetB = [
      this.props.data.imageSP1.childImageSharp.fluid.src,
      this.props.data.imageSP2.childImageSharp.fluid.src,
      this.props.data.imageSP3.childImageSharp.fluid.src,
      this.props.data.imageSP4.childImageSharp.fluid.src,
      this.props.data.imageSP5.childImageSharp.fluid.src,
      this.props.data.imageSP6.childImageSharp.fluid.src,
      this.props.data.imageSP7.childImageSharp.fluid.src
    ]

    return (
      <Layout location={this.props.location.pathname}>
        <SEO title="Carbon Connections - 2.5 Earth Takes a Breath" />
        <Container className="mb-5" fluid>
          <Row>
            <Col md="auto">
              <SideBarSeTwo location={this.props.location.pathname} />
            </Col>
            <Col>
              <CCBreadcrumb
                pathname={this.props.location.pathname}
                title={'2.5 Earth Takes a Breath'}
                replace={this.props.replace}
              />
              <Alert
                className="w-100"
                variant="warning"
              >
                BSCS is maintaining this educational resource for archival purposes. Some information may be out of date. We are no longer supporting the resource for classroom use.
              </Alert>
              <Card>
                <Card.Body>

                  <ContentHeader
                    icon={ unit2Icon }
                    iconAlt="Unit 2 icon"
                    studentPageHeader="Unit 2: Carbon Now"
                    unitNumber="2.5"
                    studentPageHeaderSub="Earth Takes a Breath"
                    sectionA={ <>Key Things You Learned</> }
                    sectionALink="/unit-2/2.5-earth-takes-a-breath/#key-things-you-learned"
                    sectionB={ <>Comparing Scales</> }
                    sectionBLink="/unit-2/2.5-earth-takes-a-breath/#comparing-scales"
                    sectionC={ <>Energy for Human</> }
                    sectionCLink="/unit-2/2.5-earth-takes-a-breath/#energy-for-humans"
                    sectionD={ <>Point Barrow, Alaska</> }
                    sectionDLink="/unit-2/2.5-earth-takes-a-breath/#point-barrow-alaska"
                    sectionE={ <>South Pole, Antarctica</> }
                    sectionELink="/unit-2/2.5-earth-takes-a-breath/#south-pole-antarctica"
                  />

                  <FocusQuestion
                    focusQuestionBodyText={ <>How do carbon dioxide (CO<sub>2</sub>) records at the poles of Earth reflect the carbon cycle in the biosphere?</> }
                  />

                  <Introduction
                    paragraphOne={
                      <>
                        <Row className="d-md-none">
                          <Col>
                            <Img
                              className="rounded img-fluid mb-3"
                              fluid={this.props.data.image44.childImageSharp.fluid}
                              alt="Power plant"
                            />
                          </Col>
                        </Row>
                        <Img
                          className="smallImageLeft img-fluid d-none d-md-block mb-3 mr-3"
                          fluid={this.props.data.image44.childImageSharp.fluid}
                          alt="Power plant"
                        />

                        <p>
                          In this unit, you have really engaged in the carbon cycle. After the aquarium experiment (Lesson 2.2), you are now thinking about carbon moving in systems around Earth. That's global thinking!
                        </p>
                      </>
                    }

                    paragraphTwo={
                      <>
                        <p>You have looked at sources and sinks of carbon on land and in the oceans. Photosynthesis and respiration move that carbon among reservoirs continually. Moreover, several factors either increased or decreased the rate of these processes.</p>
                      </>
                    }

                    paragraphThree={
                      <>
                        <p>In this lesson, you get to show what you have learned about carbon cycling. You have studied some records of CO<sub>2</sub> in the atmosphere, such as at Mauna Loa. What do the data look like at other latitudes, such as at the poles? To do this, you will investigate data from the South Pole and very near the North Pole. You will learn:</p>
                      </>
                    }

                    paragraphFour={
                      <>
                        <Row className="d-md-none">
                          <Col>
                            <Img
                              className="rounded img-fluid mb-3 imageBorder"
                              fluid={this.props.data.image43.childImageSharp.fluid}
                              alt="Preindustrial (before 1850) vs Modern (1870-Today) model charts"
                            />
                          </Col>
                        </Row>
                        <Img
                          className="smallImageRight img-fluid imageBorder d-none d-md-block ml-2"
                          fluid={this.props.data.image43.childImageSharp.fluid}
                          alt="Preindustrial (before 1850) vs Modern (1870-Today) model charts"
                        />
                      </>
                    }

                    paragraphFive={
                      <>
                        <ul>
                          <li>Factors that affect the rates of photosynthesis and respiration (e.g., seasonal sunlight) also affect the rate that carbon moves in the biosphere and geosphere.</li>
                          <li>Processes that cycle carbon act at different scales in the Earth system.</li>
                          <li>Fossil fuels supply most of the energy needs for the United States and other countries.</li>
                        </ul>

                        <p>Remember to use the focus question to guide your learning in this lesson.</p>
                      </>
                    }
                  />

                  <ButtonTop />

                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header id="key-things-you-learned" className="cardHeader">
                          <h2>
                            A. <span className="headerFontBlue">Key Things You Learned</span>
                          </h2>
                        </Card.Header>
                        <Card.Body className="cardBodyBackground">
                          <p>
                            Before you start your activity, review some ideas you have learned in Unit 2.
                          </p>

                          <p className="lead"><em>Lesson 2.1</em></p>
                          <ul>
                            <li>Different processes move carbon among different parts of the Earth system.</li>
                            <li>You can use a box diagram to model how carbon moves in the Earth system.</li>
                            <li>Carbon transfer in the Earth system involves organisms in the biosphere.</li>
                            <li>Humans use a variety of technologies to monitor carbon moving in the Earth system.</li>
                          </ul>

                          <p className="lead"><em>Lesson 2.2</em></p>
                          <ul>
                            <li>Photosynthesis and respiration move CO<sub>2</sub> into and out of the biosphere.</li>
                            <li>We can use of scale models to understand larger-scale processes on Earth.</li>
                            <li>Different factors, such as patterns of light, affect the rate of photosynthesis and respiration.</li>
                          </ul>

                          <p className="lead"><em>Lesson 2.3</em></p>
                          <ul>
                            <li>Photosynthesis and respiration are two important processes that help in understanding the patterns of CO<sub>2</sub> in the atmosphere.</li>
                            <li>CO<sub>2</sub> in the atmosphere has a trend that is steadily rising because of human activities and the use of fossil fuels.</li>
                            <li>Scientific models and simulations help you understand the transfer of carbon by different processes in the Earth system.</li>
                          </ul>

                          <p className="lead"><em>Lesson 2.4</em></p>
                          <ul>
                            <li>Fossil fuels consist of carbon from the past that has been stored in the geosphere and is used by humans.</li>
                            <li>Forests and oceans can take up only some of the fossil carbon (about 50 percent) that is added back into the Earth system by humans.</li>
                            <li>Fossil fuels are a key part of generating electrical energy for use by humans.</li>
                          </ul>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <ButtonTop />

                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header id="comparing-scales" className="cardHeader">
                          <h2>
                            B. <span className="headerFontBlue">Comparing Scales</span>
                          </h2>
                        </Card.Header>
                        <Card.Body className="cardBodyBackground">
                          <p>
                            In Lesson 2.2, a high school class made measurements in an aquarium with aquatic plants. These connected to rates of photosynthesis and respiration depending on whether it was day or night. During photosynthesis, carbon dioxide is taken in and oxygen is released. Recall the reaction: <br />
                            6CO<sub>2</sub> + 6H<sub>2</sub>O + Energy &rarr; C6H<sub>12</sub>O<sub>6</sub> + 6O<sub>2</sub>
                          </p>

                          <p>
                            During respiration, the reverse is true: <br />
                            C<sub>6</sub>H<sub>12</sub>O<sub>6</sub> + 6O<sub>2</sub> &rarr; 6CO<sub>2</sub> + 6H<sub>2</sub>O + Energy for the organism
                          </p>

                          <p>
                            Get with a partner to answer the questions below. You will compare CO<sub>2</sub> data in two graphs. One graph represents carbon dioxide (CO<sub>2</sub>) in the water for an aquarium that contains an aquatic plant. It is by a window. The other is for CO<sub>2</sub> levels in air at Mauna Loa. This is a good way to show your understanding of how carbon cycling acts at very different sizes.
                          </p>

                          <Row className="d-md-none">
                            <Col>
                              <img
                                className="rounded img-fluid mb-4"
                                src={ co2TwoDays45 }
                                alt="Carbon dioxide versus time for 2 days"
                              />
                            </Col>
                          </Row>
                          <img
                            className="largeImageCenter img-fluid d-none d-md-block mb-4"
                            src={ co2TwoDays45 }
                            alt="Carbon dioxide versus time for 2 days"
                          />

                          <Row className="d-md-none">
                            <Col>
                              <img
                                className="rounded img-fluid mb-4"
                                src={ co2Natural46 }
                                alt="Carbon dioxide, natural processes, Mauna Loa"
                              />
                            </Col>
                          </Row>
                          <img
                            className="largeImageCenter img-fluid d-none d-md-block mb-4"
                            src={ co2Natural46 }
                            alt="Carbon dioxide, natural processes, Mauna Loa"
                          />

                          <ol>
                            <li>Get a handout from your teacher of these two graphs. Your teacher may have you hand it in with your answers to the following questions.</li>
                            <li>
                              The x-axes have no labels. Add labels to the diagram below.
                              <ol type="a">
                                <li>For the aquarium data, decide with your partner which part(s) of the CO<sub>2</sub> curve would be night and which would be day. Draw vertical lines between day and night corresponding to sunrise and sunset.</li>
                                <li>For the Mauna Loa data, show your estimates for what part(s) of the CO<sub>2</sub> curve correspond to each of the four seasons. Label the seasons on the curve.</li>
                                <li>On each graph, identify places where the rate of respiration equals the rate of photosynthesis.</li>
                              </ol>
                            </li>
                          </ol>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <ButtonTop />

                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header id="energy-for-humans" className="cardHeader">
                          <h2>
                            C. <span className="headerFontBlue">Energy for Humans</span>
                          </h2>
                        </Card.Header>
                        <Card.Body className="cardBodyBackground">
                          <p>
                            You are part of the carbon cycle in several ways. One way is by using electrical energy. Answer the following questions about how carbon released by humans becomes part of the biosphere.
                          </p>

                          <ol start="3">
                            <li>
                              When humans use fossil fuels to generate energy, about 25 percent of the CO<sub>2</sub> emitted is taken up by the oceans. Another 25 percent is taken up by land plants.
                              <ol type="a">
                                <li>What happens to the remaining 50 percent of CO<sub>2</sub> emitted when using fossil fuels? Use evidence from your chapter to support your answer.</li>
                                <li>Are soils able to take up part of the CO<sub>2</sub> in the atmosphere? In other words, would you expect that soils are a source or sink of carbon for the atmosphere?</li>
                              </ol>
                            </li>
                            <li>
                              Most of the electricity for humans comes from the combustion of fossil fuels at power plants. For example, supporting 75 homes in Denver for one day requires about 1,235 kilograms of coal. That coal gives off about 1,872 kg of CO<sub>2</sub>. This is shown in the diagram below. Get with a partner and answer the questions that follow about this process.
                              <ol type="a">
                                <li>
                                  You can compare this process with carbon reactions in the biosphere. Does this process seem more similar to photosynthesis or to cellular respiration? Give one reason why. <br />
                                  <em>Hint: It may help you to review those reactions in the section above.</em>
                                </li>
                                <li>Coal is a hydrocarbon. Based on your answer in step a, what would you predict happens to the hydrogen in coal?</li>
                                <li>On land, the CO<sub>2</sub> could be taken up by a plant or tree. If the CO<sub>2</sub> entered the ocean, what is a carbon-bearing substance that the carbon atom could become a part of?</li>
                              </ol>
                            </li>
                          </ol>

                          <Row className="d-md-none">
                            <Col>
                              <img
                                className="rounded img-fluid mb-4 imageBorder"
                                src={ denverPlant47 }
                                alt="Denver power plant"
                              />
                            </Col>
                          </Row>
                          <img
                            className="largeImageCenter img-fluid imageBorder d-none d-md-block"
                            src={ denverPlant47 }
                            alt="Denver power plant"
                          />
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <ButtonTop />

                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header id="point-barrow-alaska" className="cardHeader">
                          <h2>
                            D. <span className="headerFontBlue">Field Trip 1: Point Barrow, Alaska</span>
                          </h2>
                        </Card.Header>
                        <Card.Body className="cardBodyBackground">
                          <p>
                            You have taken some field trips in Carbon Connections. This is fun, as you might not ever visit some of these places. The introduction to this lesson talked about going to the poles. This helps you understand the carbon cycle at different places on Earth. Let's travel to one now&mdash;Point Barrow, Alaska!
                          </p>

                          <Row className="d-md-none">
                            <Col>
                              <Img
                                className="rounded img-fluid mb-3"
                                fluid={this.props.data.image48.childImageSharp.fluid}
                                alt="Point Barrow, Alaska"
                              />
                            </Col>
                          </Row>
                          <Img
                            className="largeImageCenter img-fluid d-none d-md-block mb-3"
                            fluid={this.props.data.image48.childImageSharp.fluid}
                            alt="Point Barrow, Alaska"
                          />

                          <p>
                            Point Barrow is the northernmost point in the United States. It lies on a point of land protruding out into the Arctic Ocean. It is above the Arctic Circle, so in the middle of winter it is dark for 24 hours; in summer, it is light for 24 hours.
                          </p>

                          <p>
                            Like Mauna Loa, Point Barrow has a climate station. It regularly measures CO<sub>2</sub> levels of the air. These are reported as monthly averages. The ecosystem, or biome, at Point Barrow is Arctic tundra, yet the air arriving to the station has had a long journey around the Arctic. Before arriving at Point Barrow, much of the air has been interacting with boreal forests and tundra in northern Europe and Siberia (see map).
                          </p>

                          <Button
                            block
                            className="btn-primary mb-4"
                            size="lg"
                            onClick={() => this.setState({ AisOpen: true })}
                          >
                            Open Point Barrow Field Trip
                          </Button>

                          <p>
                            Examine with a partner the CO<sub>2</sub> record from Point Barrow in the steps below. The most recent data extends to the start of 2008.
                          </p>

                          <Row className="d-md-none">
                            <Col>
                              <img
                                className="rounded img-fluid mb-3"
                                src={ co2PointBarrow49 }
                                alt="Carbon dioxide, Point Barrow, Alaska"
                              />
                            </Col>
                          </Row>
                          <img
                            className="largeImageCenter img-fluid d-none d-md-block mb-3"
                            src={ co2PointBarrow49 }
                            alt="Carbon dioxide, Point Barrow, Alaska"
                          />

                          <ol start="5">
                            <li>
                              Get from your teacher a handout of CO<sub>2</sub> data for Point Barrow (2002-2008). Select a calendar year and label the months. Use these labels as a reference for other years as you do the next steps.
                              <ol type="a">
                                <li>In what time of the year is the amount of CO<sub>2</sub> the highest?</li>
                                <li>In what time of the year is the amount of CO<sub>2</sub> the lowest?</li>
                                <li>Look at when the CO<sub>2</sub> levels are decreasing. What process(es) in the biosphere could explain that decrease?</li>
                                <li>Look at when the CO<sub>2</sub> levels are increasing. What process(es) in the biosphere could explain that decrease?</li>
                              </ol>
                            </li>

                            <Row className="d-md-none">
                              <Col>
                                <img
                                  className="rounded img-fluid mb-3 mt-3"
                                  src={ northernHemA50 }
                                  alt="Northern hemisphere carbon dioxide (Mauna Loa, Barrow"
                                />
                              </Col>
                            </Row>
                            <img
                              className="largeImageCenter img-fluid d-none d-md-block mt-3 mb-3"
                              src={ northernHemA50 }
                              alt="Northern hemisphere carbon dioxide (Mauna Loa, Barrow)"
                            />

                            <li>
                              Look at the combined CO<sub>2</sub> data for 2002-2008 from Mauna Loa and Point Barrow. Compare these data in the following steps.
                              <ol type="a">
                                <li>Write one way they are similar.</li>
                                <li>Write one way that they are different.</li>
                                <li>Write two sentences about this graph using the phrases "<em>rate of photosynthesis</em>," and "<em>rate of respiration</em>."</li>
                              </ol>
                            </li>

                            <Row className="d-md-none">
                              <Col>
                                <img
                                  className="rounded img-fluid mb-3 mt-3"
                                  src={ northernHemB51 }
                                  alt="Northern hemisphere carbon dioxide (Mauna Loa, Barrow"
                                />
                              </Col>
                            </Row>
                            <img
                              className="largeImageCenter img-fluid d-none d-md-block mt-3 mb-3"
                              src={ northernHemB51 }
                              alt="Northern hemisphere carbon dioxide (Mauna Loa, Barrow)"
                            />

                            <li>Here's another graph of combined CO<sub>2</sub> data for 1980-2010 for Mauna Loa and Point Barrow. Assuming that nothing changes in the next 10 years, make a prediction for levels of CO<sub>2</sub> at 2020.</li>
                          </ol>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <ButtonTop />

                  <Row>
                    <Col>
                      <Card className="mb-4">
                        <Card.Header id="south-pole-antarctica" className="cardHeader">
                          <h2>
                            E. <span className="headerFontBlue">Field Trip 2: South Pole, Antarctica</span>
                          </h2>
                        </Card.Header>
                        <Card.Body className="cardBodyBackground">
                          <p>
                            Don't put your coat away! For second field trip, you are going to the South Pole.
                          </p>

                          <p>
                            The South Pole is at the center of the continent of Antarctica. Most of Antarctica is covered by an ice sheet nearly two miles thick at some locations. Rugged mountains extend high above the ice sheet in spots, giving clues to Antarctic's geologic past. Some of these rocks contain dinosaur bones, indicating that Antarctica was not always so cold.
                          </p>

                          <p>
                            You saw an ice core record from Antarctica in Unit 1. Another important record of Antarctica's climate comes from a climate station on top of the ice at the South Pole. Most of Antarctica is surrounded by ocean (map). Winds circling Antarctica mix air that is interacting with the oceans and land. Both the land and ocean have seasons when productivity is high, and when it is low. That air also arrives at the climate station at the South Pole.
                          </p>

                          <Button
                            block
                            className="btn-primary mb-4"
                            size="lg"
                            onClick={() => this.setState({ BisOpen: true })}
                          >
                            Open South Pole Field Trip
                          </Button>

                          <p>
                            Investigate part of the CO<sub>2</sub> record from the South Pole in the steps below. The most recent data extends to the start of 2006, so you will look again at a six-year period (2000-2006).
                          </p>

                          <Row className="d-md-none">
                            <Col>
                              <img
                                className="rounded img-fluid mb-3"
                                src={ co2SouthPole52 }
                                alt="South pole carbon dioxide"
                              />
                            </Col>
                          </Row>
                          <img
                            className="largeImageCenter img-fluid d-none d-md-block mb-3"
                            src={ co2SouthPole52 }
                            alt="South pole carbon dioxide"
                          />

                          <ol start="8">
                            <li>
                              Get from your teacher a handout of CO<sub>2</sub> data for the South Pole and Mauna Loa (2000-2006). Select a calendar year and label the months. Use these labels as a reference for other years as you do the steps below.
                              <ol type="a">
                                <li>Write one way they are similar.</li>
                                <li>Write one way that they are different.</li>
                                <li>Write two sentences about this graph using the phrases "<em>rate of photosynthesis</em>," and "<em>rate of respiration</em>."</li>
                                <li>What is the main difference you see between a hemisphere dominated by land, and a hemisphere dominated by ocean?</li>
                              </ol>
                            </li>
                          </ol>

                          <Row className="d-md-none">
                            <Col>
                              <img
                                className="rounded img-fluid mb-3"
                                src={ co2SouthPoleMauna53 }
                                alt="South pole and Mauna Loa carbon dioxide"
                              />
                            </Col>
                          </Row>
                          <img
                            className="largeImageCenter img-fluid d-none d-md-block mb-3"
                            src={ co2SouthPoleMauna53 }
                            alt="South pole and Mauna Loa carbon dioxide"
                          />

                          <p>
                            By looking at a selection of CO<sub>2</sub> data from air in Unit 2, you started to see patterns and trends. These patterns and trends are related to interactions among parts of the carbon cycle in Earth's system. Having distinct patterns and trends offer scientists something else when working to understand the carbon cycle and climate. The patterns help them develop models of the systems. With those models, scientists can test explanations for how carbon cycles.
                          </p>

                          <p>
                            Listen to your teacher for when you will start Unit 3 of <em>Carbon Connections</em>. In that unit, you will get to try your hand at a climate model with several factors. Several of those factors relate to the carbon cycle. Using the model, you will get to investigate why that is!
                          </p>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>

                  <ButtonTop />

                  <ButtonNext
                    ButtonText="Unit 3: Future Carbon and You"
                    NextLink="/unit-3/3.0-overview"
                  />

                  {AisOpen && (
                    <Lightbox
                      mainSrc={imageSetA[photoIndexA]}
                      nextSrc={imageSetA[(photoIndexA + 1) % imageSetA.length]}
                      prevSrc={imageSetA[(photoIndexA + imageSetA.length - 1) % imageSetA.length]}
                      onCloseRequest={() => this.setState({ AisOpen: false })}
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndexA: (photoIndexA + imageSetA.length - 1) % imageSetA.length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndexA: (photoIndexA + 1) % imageSetA.length,
                        })
                      }
                    />
                  )}

                  {BisOpen && (
                    <Lightbox
                      mainSrc={imageSetB[photoIndexB]}
                      nextSrc={imageSetB[(photoIndexB + 1) % imageSetB.length]}
                      prevSrc={imageSetB[(photoIndexB + imageSetB.length - 1) % imageSetB.length]}
                      onCloseRequest={() => this.setState({ BisOpen: false })}
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndexB: (photoIndexB + imageSetB.length - 1) % imageSetB.length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndexB: (photoIndexB + 1) % imageSetB.length,
                        })
                      }
                    />
                  )}

                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default EarthTakesABreath25

export const query = graphql`
  query {
    image43: file(relativePath: { eq: "student-pages/unit-2/43_preindustrial_model.png" }) {
      ...rowImage
    }
    image44: file(relativePath: { eq: "student-pages/unit-2/44_powerplants.jpg" }) {
      ...rowImage
    }
    image48: file(relativePath: { eq: "student-pages/unit-2/48_point_barrow_alaska.jpg" }) {
      ...rowImage
    }
    imagePB1: file(relativePath: { eq: "student-pages/unit-2/25a-point-barrow/01_point_barrow_sign.jpg" }) {
      ...rowImage
    }
    imagePB2: file(relativePath: { eq: "student-pages/unit-2/25a-point-barrow/02_climate_station_summer.jpg" }) {
      ...rowImage
    }
    imagePB3: file(relativePath: { eq: "student-pages/unit-2/25a-point-barrow/03_climate_station_winter.jpg" }) {
      ...rowImage
    }
    imagePB4: file(relativePath: { eq: "student-pages/unit-2/25a-point-barrow/04_wildlife.jpg" }) {
      ...rowImage
    }
    imagePB5: file(relativePath: { eq: "student-pages/unit-2/25a-point-barrow/05_recording_climate_data.jpg" }) {
      ...rowImage
    }
    imageSP1: file(relativePath: { eq: "student-pages/unit-2/25b-south-pole/01_south_pole_outer_space.jpg" }) {
      ...rowImage
    }
    imageSP2: file(relativePath: { eq: "student-pages/unit-2/25b-south-pole/02_amundsen_scott_station.jpg" }) {
      ...rowImage
    }
    imageSP3: file(relativePath: { eq: "student-pages/unit-2/25b-south-pole/03_climate_station.jpg" }) {
      ...rowImage
    }
    imageSP4: file(relativePath: { eq: "student-pages/unit-2/25b-south-pole/04_spo_observatory.jpg" }) {
      ...rowImage
    }
    imageSP5: file(relativePath: { eq: "student-pages/unit-2/25b-south-pole/05_observatory_climate_station.jpg" }) {
      ...rowImage
    }
    imageSP6: file(relativePath: { eq: "student-pages/unit-2/25b-south-pole/06_weather_balloon.jpg" }) {
      ...rowImage
    }
    imageSP7: file(relativePath: { eq: "student-pages/unit-2/25b-south-pole/07_south_pole_sunset.jpg" }) {
      ...rowImage
    }
  }
`
